import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/ai-trader.jpg';
import { Dropdown } from 'react-bootstrap';
import './css/Header.css';

function Header({ darkMode, toggleDarkMode, isLoggedIn, handleLogout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`navbar navbar-expand-lg ${darkMode ? 'dark-mode' : ''}`}>
      <div className="container-fluid">
        <Link to="/" className="navbar-brand align-items-center">
          <img src={logo} alt="AI Trader" width="50" height="50" className="logo" />
          <h1 className="mb-0 ml-2">AI Trader</h1>
        </Link>

        {/* Hamburger Menu */}
        <button className="menu-toggle d-lg-none" onClick={toggleMenu}>
          <div className="hamburger"></div>
          <div className="hamburger"></div>
          <div className="hamburger"></div>
        </button>

        <nav className={`nav-menu ${isMenuOpen ? 'show' : ''}`}>
          <button className="btn btn-outline-light" onClick={toggleDarkMode}>
            Switch to {darkMode ? 'Light' : 'Dark'} Mode
          </button>

          {isLoggedIn ? (
            <div className="logged-in-menu">
              <Dropdown>
                <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                  Menu
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/dashboard">Dashboard</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/account">Account</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/advisor">Advisor</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/game">Game</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <button onClick={handleLogout} className="btn btn-danger">Log Out</button>
            </div>
          ) : (
            <div className="logged-out-menu">
              <Link to="/signup" className="btn btn-outline-primary mx-2">Sign Up</Link>
              <Link to="/login" className="btn btn-primary">Log In</Link>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
